import React, {useEffect} from 'react';
import styled from 'styled-components'
import GA from 'react-ga'

const Body = styled.div`
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  max-width: 600px;
`;

const App = () => {
  useEffect( () => {
    try {
		  GA.initialize([{trackingId: 'UA-140237254-1'}]);
		} catch(e) {
		  console.error("GA initialize error:", e.message);
		}
    if(process.env.NODE_ENV === 'production') {
      GA.pageview(window.location.pathname + window.location.search);
    }
  },[]);

  return (
      <Body>
        <h1>Jimmy &amp; Kate,</h1>
        Congrats on your engagement! (probably). If you'd like to purchase this domain to use for your own site, please email <a href="mailto:jimmyandkatedotcom@gmail.com">jimmyandkatedotcom@gmail.com</a>. We no longer need it, but don't want to immediately give it up to the rest of the internet.
        <br/>
        <br/>
        <span role="img" aria-label="cheers">✌️</span> Cheers,
        <br/>
        Jimmy &amp; Kate
      </Body>
    )
}

export default App;
